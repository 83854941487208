export const sizing = {
  ss: "12px",
  sm: "24px",
  sl: "36px",
  ms: "48px",
  mm: "60px",
  ml: "72px",
  ls: "84px",
  lm: "96px",
  ll: "108px",
  headerHeight: "60px",
  logoHeight: "72px",
};
